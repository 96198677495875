//const { add_picture } = require("../../../server/inc/db");

const params = new URLSearchParams(window.location.search);

let page_vars={};
for (const [key, value] of params.entries())
	page_vars[key]=value;

let page_lng="es";

const data_url="1y_4Ia2Lj-ZJUPFPoO_Zpb-qGTrSlUbj4lVQk8nkDkoE";
let data={events:null,comments:null};


const server_url= location.hostname === "localhost" 
? "http://localhost:8080"
: "https://server.olimpo.club";

function get_msg(msg)
{
	const e=document.querySelector("[aria-msg="+msg+"_"+page_lng+"]");
	if(e)
		return(e.innerHTML);
	
	return("");
}
function show_lng(lng)
{
	document.querySelectorAll(".lng").forEach(element=>{
		element.style.display = 'none';
	});
	document.querySelectorAll(".lng_"+lng).forEach(element=>{
		element.style.display = 'inline';
	});	
	page_lng=lng;
	localStorage["wircos_lng"]=lng;
}
	
function lng_click(e)
{
	var lng=this.getAttribute("href");
	show_lng(lng);
	e.preventDefault();
}	

function formatBytes(bytes, decimals = 2) 
{
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function to_json(data)
{
	let res=[];
	if(data)
	{
		if(data[0][0].charAt(0)=="#")
		{
			res={};
			for(let a=1;a<data.length;a++)
				res[data[a][0]]=data[a][1];
		}else{
		    for(let a=1;a<data.length;a++)
			{
				d={};
        		for(let b=0;b<data[0].length;b++)
        		{
            		d[data[0][b].toLowerCase()]=data[a][b];
        		}				
				res.push(d);
			}
		}
	}
    return(res);
}

async function load_data(document,spreadsheet)
{
	try
	{
    	const url = "https://sheets.googleapis.com/v4/spreadsheets/" + document + "/values/"+
                spreadsheet+"?alt=json"+"&key=AIzaSyD8c-oT4E9kvfAvrgUFOsm0b9wdDYOEI34";
    	const res=await fetch(url);
    	const data=await res.json();  
		return(to_json(data.values));
	}catch(e)
	{
		return(null);
	}
}
function update_data()
{
	const today=new Date();
	data.new_events={};
	data.last_events={};
	data.events.forEach(event=>{
		event.event_comments=[];
		data.comments.forEach(co=>{
			if(co.event_id===event.event_id)
				event.event_comments.push(co);
		});

		const edate=event.event_date.split("/");
		//if(new Date(`${edate[2]}-${edate[1]}-${edate[0]}`)>today)
		if(event.event_new=="1")
			data.new_events[event.event_id]=event;
		else
			data.last_events[event.event_id]=event;
			//data.last_events.push(event);
	});
	delete data.events;
	delete data.comments;
}

function fill_data(to,data)
{
	to.querySelectorAll("[aria-field]").forEach(item=>{
		const field=item.getAttribute("aria-field");
		if(data[field])
		{
			if(item.hasAttribute("aria-item"))
			{
				add_items(item,data[field],item.getAttribute("aria-item"));	
			}else{
				if(item.hasAttribute("aria-href"))
				{
					item.href+="?"+data[field];
				}else{
					if(item.hasAttribute("aria-select"))
					{

					}else{
						if(item.tagName==="IMG")
						{
							item.src=data[field];
						}else{
							if(item.tagName==="VIDEO")
							{
								item.querySelector("source").setAttribute("src",data[field]);
							}else
								item.innerHTML=data[field];
								//if(item.hasAttribute("href"))
								//	subitem.setAttribute("href",data[a][subitem.getAttribute("href")]);
						}
					}
				}
			}
		}
	});
}

function add_items(parent,data,type)
{
	parent.innerHTML="";
	const origin=document.querySelector("#elements ."+type);
	for(el in data)
	{
	//data.forEach(el=>{
		let node=origin.cloneNode(true)
		fill_data(node,data[el]);
		parent.append(node);
	//});
	}
}

function show_contact_result(el)
{
	if(el)
	{
		document.querySelector("."+el).classList.remove("hide");
		document.querySelector(".contact_result").classList.remove("hide");
	}else{
		document.querySelector(".contact_result").classList.add("hide");
		document.querySelectorAll(".contact_result_ok, .contact_result_ko").forEach(element=>{
			element.classList.add("hide");
		});
	}
}

async function load()
{
	show_lng(page_lng)
	document.querySelectorAll(".btn_lng").forEach(element=>{
		element.addEventListener('click', lng_click,false);
	});

	if(document.getElementById("header"))
		document.getElementById("header").classList.remove("header_hide");

	document.querySelectorAll(".faq_question").forEach(element=>{
		element.addEventListener('click', function(e)
		{			
			e.preventDefault();
			this.classList.toggle("faq_arrow_extend");
			this.nextElementSibling.classList.toggle("faq_answer_visible");
		},false);
	});

	const nav=document.querySelector("nav");
	if(nav)
	{
		window.addEventListener("scroll",(event)=>
		{
			if(window.scrollY>50)
				nav.classList.add("dark");
			else 
				if(nav.classList.contains("dark")) nav.classList.remove("dark");
		});
	}

	if(document.querySelector(".home_page"))
	{
		for(key in data)
		{
			data[key]=await load_data(data_url,key);
		}
		update_data();
		localStorage.olimpo_events=JSON.stringify(data);

	}else{
		data=JSON.parse(localStorage.olimpo_events);
		console.log("the data:",data);
	}

	console.log(data);

	const parent=document.querySelector(".row_events");
	if(parent)
		add_items(parent,data.new_events,"item_event");
	
	const last=document.querySelector(".last_events");
		if(last)
			add_items(last,data.last_events,"item_last");


	document.querySelectorAll(".last_events_arrow").forEach(el=>{
		el.addEventListener("click",e=>{
			e.preventDefault();
			let offset=document.querySelector(".last_events .item_last").offsetWidth+50;
			offset*=parseInt(el.getAttribute("href"));
			document.querySelector(".last_events").scrollBy(offset,0);
		})
	})		

	if(document.querySelector(".event_page"))
	{
		let event_id=null;
		for (const [key, value] of params)
		{
			event_id=key;
			break;
		}

		const event=data.new_events[event_id];
		if(event_id)
		{
			fill_data(document.querySelector("body"),event)
		}

		console.log(event_id);
	}
	

	//const profile_pictures=document.querySelector(".profile_pictures");
	if(document.getElementById("btn_load_image"))
	{
		document.getElementById("btn_load_image").addEventListener("change",function(e)
		{
			e.preventDefault();
			if(this.files.length === 0) return;
			const imageFile = this.files[0];
		
			//console.log(e);
			const target=document.getElementById(this.getAttribute("aria-from")); //document.getElementById("btn_add_picture");
	
			const profile_pictures=document.getElementById(target.getAttribute("aria-to"));
	
			const reader = new FileReader();
			reader.onload = (function(aImg){
				return function(event) 
			 	{ 
					// TODO: pasar type
					upload_picture(e.target,profile_pictures.getAttribute("picture-type"),(res,data)=>{
						e.target.value="";
						if(!res)
						{
							// TODO: mostrar errores
							alert("Error: "+data);
							return;
						}

						add_image(profile_pictures,event.target.result,data);
		
					});
				}; 
			})(target);
			reader.readAsDataURL(imageFile);
		});

		document.querySelectorAll(".btn_add_picture").forEach(el=>{
			el.addEventListener("click",e=>{
				e.preventDefault();
				const btn=document.getElementById("btn_load_image")
				btn.setAttribute("aria-from",el.getAttribute("id"));
				console.log("Desde: ",el.getAttribute("id"));
				btn.click();
			});
		});

		document.getElementById("user_type").addEventListener("change",e=>{
			//alert(e.target.value);
			if(e.target.value<2)
			{
				document.querySelector(".profile_member.profile_2").classList.add("hide");
			}else{
				document.querySelector(".profile_member.profile_2").classList.remove("hide");
			}
			document.querySelectorAll("label.profile_types").forEach(el=>{
				el.classList.add("hide");
			});
			document.querySelectorAll(".profile_member label.type_"+e.target.value).forEach(el=>{
				el.classList.remove("hide");
			});
		});

		document.querySelectorAll(".profile_tags a ").forEach(el=>{
			el.addEventListener("click",e=>{
				e.preventDefault();
				el.classList.toggle("selected");
			});
		});

	}

	document.querySelectorAll(".nav_menu a").forEach(el=>{
		el.addEventListener("click",e=>{
			document.getElementById("side-menu").checked=false;
		})
	});

	
	if(document.getElementById("btn_register"))
	{
		document.getElementById("btn_register").addEventListener("click",function(e)
			{
				e.preventDefault();
				show_contact_result(null);
				document.querySelectorAll("#contact_form input, textarea").forEach(element=>{

					let el=element.getAttribute("type")=="checkbox" ? element.parentElement.querySelector(".checkmark") : element;

					if(element.checkValidity())
						el.classList.remove("contact_error");
					else 
						el.classList.add("contact_error");
				});

				if(document.querySelector(".contact_error"))
				{
					show_contact_result("contact_error_fields");
				}else{

					fetch(server_url+"/session/start",{
						method: "POST",
						headers: {"Access-Control-Allow-Origin": "*",
								  "Content-Type": "application/json"
								},
						body:JSON.stringify({
							user_email:document.getElementById("user_email").value,
							user_lng:page_lng		
						})
					}).then((response)=>{
						if (response.status >= 200 && response.status <= 299) 
							return response.json();
						
						show_contact_result("contact_error_server");
					}).then((response)=>{
						console.log(response);
						if(response.status)
						{   
							show_contact_result("contact_result_ok");
							document.getElementById("btn_register").classList.add("hide");						
						}else{
							if(response.status==100)
								show_contact_result("contact_error_email");
							else
								show_contact_result("contact_error_server");
						}
					});
				}
			});
	}

	if(document.getElementById("account_page"))
	{
		document.getElementById("btn_logout").addEventListener('click',e=>{
			e.preventDefault();
			show_logged(false);
			localStorage.removeItem("olimpo_session");
			localStorage.removeItem("olimpo_useremail");
		});

		document.querySelector(".login_error").classList.add("hide");
		document.querySelector(".login_wait").classList.add("hide");

	
		document.getElementById("btn_login").addEventListener('click',handleLogin);

		if(localStorage["olimpo_session"])
		{
			test_token();
		}else{
			if(page_vars["token"])
			{
				if(localStorage["olimpo_user"])
				{
					validate_token(page_vars["token"],function(status,data)
					{
						if(status)
						{ 
							localStorage["olimpo_session"]=data;
							test_token();
						}else{
							show_logged(false);
						}
					});
				}else{
					show_logged(false);
				}
			}else{
				show_logged(false);
			}
		}
	}/*else{
		if(localStorage["olimpo_session"])
		{
			test_token();
			//show_logged(true);
		}else{
			show_logged(false);
		}
	}*/

	document.querySelectorAll("[aria-section").forEach(el=>{
		el.addEventListener('click', function(e)
		{	
			e.preventDefault();
			if(el.classList.contains("active")) return;

			const parent=document.getElementById(el.getAttribute("aria-section"));
			const prev=parent.querySelector("[aria-section].active");
			if(prev)
			{
				prev.classList.remove("active");
				document.getElementById(prev.getAttribute("href")).classList.add("hide");
			}

			el.classList.add("active");
			document.getElementById(el.getAttribute("href")).classList.remove("hide");
			window.scrollTo({top: 0});

		},false);

	});

	return;

	document.querySelectorAll("[aria-btn-trigger]").forEach(element=>{
		element.addEventListener('keypress', function(e)
		{
			if(e.key==="Enter")
			{
				document.getElementById(this.getAttribute("aria-btn-trigger")).dispatchEvent(new Event('click'));
			}
		},false);
	});

	document.querySelectorAll(".select_picture").forEach(element=>{
		const target=document.getElementById(element.getAttribute("aria-target"));
		element.addEventListener('click', function(e)
		{
			e.preventDefault();
			target.click();
		});
		
		target.addEventListener('change', function(e)
		{
			e.preventDefault();
			if(this.files.length === 0) return;
			var imageFile = this.files[0];
		
			var reader = new FileReader();
			reader.onload = (function(aImg){
				 return function(e) 
				 { 
					const img=element.querySelector(".select_picture_image");
					img.setAttribute("src",e.target.result);
					img.classList.remove("hide");
					element.querySelector(".select_picture_default").classList.add("hide");
				}; 
			})(element);
			reader.readAsDataURL(imageFile);
		},false);
	});


	if(document.getElementById("upload_files"))	//btn_change_nft
	{
		document.getElementById("upload_files").addEventListener("click",function(e)
		{
			e.preventDefault();
			document.getElementById("e_nft_file").click();
		});
		if(document.getElementById("e_nft_file"))
			document.getElementById("e_nft_file").addEventListener("change",function(e)
			{
				e.preventDefault();
				if(this.files.length === 0) return;
				document.getElementById("upload_files").innerHTML="";
				//console.log(this.files[0]);
				
				let files_content="";
				let total_size=0;
				for(let f=0;f<this.files.length;f++)
				{
					files_content+="<div class='filelist_row'>";
					files_content+="<div class='filelist_name'>"+this.files[f].name+"</div>";
					files_content+="<div class='filelist_size'>"+formatBytes(this.files[f].size,2)+"</div>";
					files_content+="</div>";
					total_size+=this.files[f].size;
					//document.getElementById("nft_filename").innerHTML+="<big>"+this.files[f].name+"</big>";
					//document.getElementById("nft_filename").innerHTML+="<small>"+
					//							formatBytes(this.files[f].size,2)+"</small>";
				}
				document.getElementById("upload_files_resume_total").innerHTML=this.files.length;
				document.getElementById("upload_files_resume_siza").innerHTML=formatBytes(total_size,2);

				document.getElementById("upload_files").innerHTML=files_content;
				//document.getElementById("nft_filename").innerHTML=this.files[0].name;
				//document.getElementById("nft_filesize").innerHTML=formatBytes(this.files[0].size,2);
				document.querySelector(".upload_files_resume").classList.remove("hide");
				//document.querySelector("#btn_change_nft .select_picture_default").classList.add("hide");
			});
	}

	
	if(document.getElementById("btn_cookies"))
	{
		if(!localStorage["kink_cookies_accept"])
		{
			document.getElementById("cookies").classList.remove("hide");
			document.getElementById("btn_cookies").addEventListener("click",function(e)
			{
				e.preventDefault();
				document.getElementById("cookies").classList.add("hide");
				localStorage["kink_cookies_accept"]="yes";
			});
		}
	}
	//if(page_vars["test"])
	//{
			document.querySelectorAll(".test").forEach(element=>{
				element.classList.remove("hide");
			});
			
			document.querySelectorAll(".no_test").forEach(element=>{
				element.classList.add("hide");
			});
			
			document.querySelectorAll(".disabled").forEach(element=>{
				element.classList.remove("disabled");
			});		
	//}


	if(document.getElementById("btn_upload"))
		document.getElementById("btn_upload").addEventListener('click',upload_nft,false);
	
	if(document.getElementById("btn_again"))
		document.getElementById("btn_again").addEventListener('click',function(e)
		{
			e.preventDefault();
			if(document.getElementById("nfts_grid"))
				load_nfts("nfts_grid",localStorage["wircos_user"],"nfts_grid_empty");	
			
			document.getElementById("upload_success").classList.add("hide");
			document.querySelector(".main_form").classList.remove("hide");
			document.getElementById("btn_upload").classList.remove("hide");
			
			document.getElementById("nft_thumb_image").setAttribute("src",null);
			document.getElementById("nft_thumb_image").classList.add("hide");
			document.querySelector("#btn_change_thumbnail .select_picture_default").classList.remove("hide");

			document.getElementById("upload_files").innerHTML="";

			document.querySelector(".upload_files_resume").classList.add("hide");
			
			document.querySelectorAll(".field_empty").forEach(element=>{
					if(element.defaultValue)
						element.value=element.defaultValue;
					else
						element.value="";
			});
		},false);
	//show_logged(false);
	

	

	document.querySelectorAll(".tooltip").forEach(element=>{
		element.addEventListener('click', function(e)
		{
			e.preventDefault();
			let text=element.parentElement.querySelector(".tooltipvalue").innerText;
			navigator.clipboard.writeText(text);
			element.parentElement.querySelector(".tooltiptext_init").classList.add("hide");
			element.parentElement.querySelector(".tooltiptext_result").classList.remove("hide");	
		});
		element.addEventListener('mouseout', function(e)
		{
			e.preventDefault();
			element.parentElement.querySelector(".tooltiptext_result").classList.add("hide");
			element.parentElement.querySelector(".tooltiptext_init").classList.remove("hide");
		});
	});

	if(document.getElementById("btn_hide_nft_popup"))
	{
		document.getElementById("btn_hide_nft_popup").addEventListener("click",function(e)
		{
			e.preventDefault();
			hide_nft_options();
		});

		document.getElementById("btn_nft_destroy").addEventListener('click', function(e)
		{
			e.preventDefault();
			
			const element=document.querySelector(".nft_popup");
			const nft_id=element.getAttribute("aria-nft");
			hide_nft_options();

			modal_show(get_msg("delete_title"),get_msg("delete")+"<i>"+element.getAttribute("aria-title")+"</i>",
					"input",function(res)
			{
				if((res) && (res==get_msg("delete_word")))
				{
					let data={"email":localStorage["wircos_user"],"lng":page_lng,
								"token":localStorage["wircos_session"],"nft":nft_id};
					fetch(server_url+"/delete_nft",{
						method: "POST",
						headers: {"Access-Control-Allow-Origin": "*",
								   "Content-Type": "application/json"},
						body: JSON.stringify(data), 
					}).then((response)=>{
						if (response.status >= 200 && response.status <= 299) 
							return response.json();
						throw Error(response.statusText);
					}).then((response)=>{
						if(response.status)
						{   
							document.getElementById("nft_"+nft_id).remove();
							show_stats(response);
							
							modal_show(get_msg("delete_title"),get_msg("delete_ok"),"info",function(res)
							{
								if(response.count==0)
									show_nfts("nfts_grid",null,"nfts_grid_empty");
							});
						}else{
							modal_show(get_msg("delete_title"),get_msg("delete_ko")+
									get_msg("error_"+response.error)+" ("+response.error+").","error");
						}
					});
				}
			});
				
		},false);

		document.getElementById("btn_nft_update").addEventListener('click', function(e)
		{
			e.preventDefault();
			
			const element=document.querySelector(".nft_popup");
			const nft_id=element.getAttribute("aria-nft");
			hide_nft_options();

			let data={"email":localStorage["wircos_user"],"lng":page_lng,
						"token":localStorage["wircos_session"],"nft":nft_id,
						"price":document.getElementById("nft_edit_price").value,
						"status":document.getElementById("nft_edit_status").value.charAt(0)};
			fetch(server_url+"/update_nft",{
				method: "POST",
				headers: {"Access-Control-Allow-Origin": "*",
							"Content-Type": "application/json"},
				body: JSON.stringify(data), 
			}).then((response)=>{
				if (response.status >= 200 && response.status <= 299) 
					return response.json();
				throw Error(response.statusText);
			}).then((response)=>{
				if(response.status)
				{   
					document.getElementById("nft_"+nft_id).setAttribute("aria-status",data.status);
					document.getElementById("nft_"+nft_id).querySelector(".nft_cost").innerText=data.price;
							
					modal_show(get_msg("update_title"),get_msg("update_ok"),"info",function(res)
					{
						// change nft id and status
					});
				}else{
					modal_show(get_msg("update_title"),get_msg("update_ko")+
							get_msg("error_"+response.error)+" ("+response.error+").","error");
				}
			});
		},false);

		document.getElementById("btn_save_profile").addEventListener('click', function(e)
		{
			e.preventDefault();
			const form = new FormData();
			form.append("email",localStorage["wircos_user"]);
			form.append("lng",page_lng);
			form.append("token",localStorage["wircos_session"]);
			form.append("account_bio",document.getElementById("account_bio").value);
			form.append("account_username",document.getElementById("account_username").value);

			if(document.getElementById("account_picture").files[0])
				form.append('account_picture',document.getElementById("account_picture").files[0]);

			fetch(server_url+"/update_settings",{
				method: "POST",
				headers: {"Access-Control-Allow-Origin": "*",
						  //"Content-Type": "multipart/form-data" //"application/json"
						},
				body: form,  
			}).then((response)=>{
				if (response.status >= 200 && response.status <= 299) 
					return response.json();
				throw Error(response.statusText);
			}).then((response)=>{
				if(response.status)
				{   
					if(document.getElementById("account_picture").files[0])
					{
						document.getElementById("nav_user_picture").setAttribute("src",
							document.getElementById("profile_user_picture").getAttribute("src"));
					}
					if(document.getElementById("account_username").value!="")
					{
						document.getElementById("nav_user_username").innerHTML=document.getElementById("account_username").value;
					}else{
						document.getElementById("nav_user_username").innerHTML=get_msg("user_anonymous");
					}
					modal_show(get_msg("update_settings_title"),
						get_msg("update_settings_ok"),"info",function(res)
					{
						
					});
				}else{
					modal_show(get_msg("update_settings_title"),get_msg("update_settings_ko")+
							get_msg("error_"+response.error)+" ("+response.error+").","error");
				}
			});
		},false);

		document.getElementById("nft_edit_view").addEventListener("click",function(e)
		{
			e.preventDefault();
			const form=document.getElementById("nft_access_form");
			form.setAttribute("action",this.getAttribute("href"));
			form.querySelector("[name=nft_access_lng]").value=page_lng;
			form.querySelector("[name=nft_access_user]").value=localStorage["wircos_user"];
			form.querySelector("[name=nft_access_session]").value=localStorage["wircos_session"];

			form.submit();

		},false);
	}

	

	// author info
	let username='all';
	
	if(document.getElementById("author_info"))
	{
		username=urlParams.keys().next().value;
		load_author_info("author_info",username);	
	}
	if(document.getElementById("explore_authors"))
	{
		show_featured_authors("explore_authors",null,5);
		load_featured_authors("explore_authors",0);	
	}

	document.querySelectorAll(".items_section").forEach(element=>{
		load_items(element,username);	
	});
	document.querySelectorAll(".grid_section").forEach(element=>{
		load_authors(element,1);
	});

}

async function upload_picture(image,type,func)
{
  	const form = new FormData();
	form.append("picture", image.files[0]); 
	form.append("email",localStorage["olimpo_user"]);
	form.append("lng",page_lng);
	form.append("type",type);
	form.append("token",localStorage["olimpo_session"]);

	try{
		const res=await fetch(server_url+"/upload_picture",{
					method: "POST",
					headers: {"Access-Control-Allow-Origin": "*",},
					body: form});
	    const json=await res.json();
		if(json.status)
			func(true,json.picture_id);
		else
			func(false,json.error);
	}catch(e){
		func(false);
	}
}

async function delete_picture(picture,func)
{
	try{
		const res=await fetch(server_url+"/delete_picture",{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						//"Access-Control-Allow-Origin": "*"
						},
					body: JSON.stringify({
						picture:picture, 
						email:localStorage["olimpo_user"],
						lng:page_lng,
						token:localStorage["olimpo_session"]
					})});
	    const json=await res.json();
		console.log(json);
		if(json.status)
			func(true);
		else
			func(false, json.error);
	}catch(e){
		console.log(e);
		func(false);
	}
}


function add_image(to,blob,picture_id,picture_state=0)
{
	const picture=document.querySelector("#elements .profile_picture_item").cloneNode(true);
	picture.setAttribute("aria-id",picture_id);
	picture.querySelector("img").setAttribute("src",blob);				
	
	picture.classList.add("state_"+picture_state);

	const btn=to.querySelector(".btn_add_picture");

	picture.querySelector(".btn_delete_picture").addEventListener("click",e=>{
		e.preventDefault();
		delete_picture(picture.getAttribute("aria-id"),(result,error)=>{
			if(!result)
			{
				alert("Error: "+error);
				return;
			}
			picture.remove();
			btn.classList.remove("hide"); 
		});
	});

	to.insertBefore(picture,btn.parentElement);

	if(to.children.length>parseInt(to.getAttribute("aria-max")))
		btn.classList.add("hide");
	
}

async function load_image(pic_id,grid,pic_state,func)
{
	fetch(server_url+"/load_picture", {
        method: 'POST',
		headers: {
			"Content-Type": "application/json",
			},
		body: JSON.stringify({
			picture:pic_id, 
			email:localStorage["olimpo_user"],
			lng:page_lng,
			token:localStorage["olimpo_session"]
		})}).then(response => {
        	if (!response.ok)
            	throw new Error('error');
        	return response.blob();
    	}).then(blob => {
			//console.log("blob: ",blob);
        	func(grid,pic_id,pic_state,URL.createObjectURL(blob));
    	}).catch(error => {
			console.log(error);
			func(grid,pic_id,null);
    	});
}

function show_logged(show,data=null)
{
	if(show)
	{
		document.querySelectorAll(".logged").forEach(element=>{
			element.classList.remove("hide");
		});
		document.querySelectorAll(".no_logged").forEach(element=>{
			element.classList.add("hide");
		});
		if(document.getElementById("user_email"))
			document.getElementById("user_email").value=localStorage["olimpo_user"];

		for(type in data.pictures)
		{
			const grid=document.querySelector(`[picture-section="${type}"]`);
			if(grid)
			{
				for(picture of data.pictures[type])
				{
					//add_image(grid,null,data,picture.pic_id);
					
					load_image(picture.pic_id,grid,picture.pic_state,(to,pic_id,pic_state,blob)=>{
						//if(blob)
						//{
							add_image(to,blob,pic_id,pic_state);
						//}
					});
				}
			}
		}


	}else{
		document.querySelectorAll(".logged").forEach(element=>{
			element.classList.add("hide");
		});
		document.querySelectorAll(".no_logged").forEach(element=>{
			element.classList.remove("hide");
		});

	}
	//document.getElementById("nav_menu").classList.remove("hide");
}

function test_token()
{
	verify_token(localStorage["olimpo_session"],localStorage["olimpo_user"],function(status,data)
		{
			if(status)
			{ 
				show_logged(true,data);
				
			}else{
				show_logged(false);
				localStorage.removeItem("olimpo_session");

			}
	});
}

async function validate_token(token,callback)
{
	try
	{
		const res=await fetch(server_url+"/session/validate/"+page_lng+"/"+token);
		let data = await res.json();
		if(!data) 
		{
			callback(false,"server_error");
			return;
		}
		if(!data.status)
		{
			callback(false,data.error);
			return;
		}
		callback(true,data.new_token);
	}catch(e)
	{
		callback(false,"server_error");
	}
}

async function verify_token(token,email,callback)
{
	try
	{
		const res=await fetch(server_url+"/session/verify/"+email+"/"+token+"/"+page_lng);
		let data = await res.json();
		console.log(data);
		if(!data) 
		{
			callback(false,"server_error");
			return;
		}
		if(!data.status)
		{
			callback(false,data.error);
			return;
		}
		callback(true,data);
	}catch(e)
	{
		console.log(e);
		callback(false,"server_error");
	}
}

function wait_for_session()
{
	if(localStorage["wircos_session"])
	{
		document.querySelector(".login_wait").classList.add("hide");
		document.querySelector(".login_close_window").classList.remove("hide");
	}else{
		setTimeout(wait_for_session,2000);
	}
}

function handleLogin(e)
{
    e.preventDefault();

	document.querySelector(".login_error").classList.add("hide");
	let data={"user_email":document.getElementById("login_email").value,"user_lng":page_lng,"server":server_url};
	if(data["user_email"]=="") return;
	
	if(this.classList.contains("btn_disabled")) return;

	this.classList.add("btn_disabled");
	fetch(server_url+"/session/start",{
                method: "POST",
                headers: {
					"Access-Control-Allow-Origin": "*",
					'Content-Type': 'application/json'},
                body: JSON.stringify(data), 
            }).then((response)=>{
				if (response.status >= 200 && response.status <= 299) 
				{
		            return response.json();
				}else{
					this.classList.remove("btn_disabled");
					document.querySelector(".login_error").innerHTML=get_msg("error_100");
					document.querySelector(".login_error").classList.remove("hide");
				}
	        }).then((response)=>{
				if(response.status)
				{
					document.querySelector(".login_form").classList.add("hide");
					document.querySelector(".login_wait").classList.remove("hide");
					localStorage["olimpo_user"]=document.getElementById("login_email").value;

					// comprobar que se está la sesión
					wait_for_session();

				}else{
					this.classList.remove("btn_disabled");
					document.querySelector(".login_error").innerHTML=get_msg("error_"+response.error);
					document.querySelector(".login_error").classList.remove("hide");
				}
			}).catch((error)=>{
				this.classList.remove("btn_disabled");
				document.querySelector(".login_error").innerHTML=get_msg("error_100");
				document.querySelector(".login_error").classList.remove("hide");
			}
		);
}



document.addEventListener("DOMContentLoaded", load, false);

